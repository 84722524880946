import React from 'react';
import { ArrowRight, MinimalCoding } from 'assets/images';
import { Button } from 'app/components/Button';
import { useNavigate } from 'react-router-dom';

export const SoftwareSignals = () => {
  const navigate = useNavigate();

  const openDocumentation = () => {
    window.open('https://docs.sov.ai/', '__blank');
  };

  return (
    <div className="flex flex-col items-center justify-center gap-y-4"> 
      {/* “Enter Here” Link */}
      <div
        className="flex flex-row justify-center items-center gap-1 mt-8 xl:mt-16 cursor-pointer"
        onClick={openDocumentation}
      >
        <div className="text-gray-300 text-base lg:text-xl font-bold">
          Enter Here
        </div>
        <div className="flex w-4 h-4 md:w-5 md:h-5">
          <img src={ArrowRight} alt="right-arrow" className="w-full h-full" />
        </div>
      </div>

      {/* Main Headline */}
      <div className="flex flex-col items-center text-center font-black p-4">
        <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white whitespace-nowrap">
          Government Data <span className="text-green-400">+</span> AI <span className="text-green-400">=</span>
        </span>
        <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-blue-400 mt-2">
          Smarter Trades
        </span>
      </div>

      {/* Sub-Headline / Description */}
      <div className="text-sm md:text-xl lg:text-2xl text-gray-300 
                      w-4/5 md:w-3/5 xl:w-2/5 text-center 
                      mb-4 mt-2"
      >
        Largest repository of ticker-linked government datasets
      </div>

      {/* CTA Buttons */}
      <div className="flex flex-row items-center justify-center gap-4 md:gap-8 mb-8">
        <Button type="gradient" onClick={openDocumentation}>
          Documentation
        </Button>
        <Button onClick={() => navigate('/home')}>
          Subscribe Here
        </Button>
      </div>

      {/* Example Datasets (tags) */}
      <div className="flex flex-wrap justify-center items-center gap-2 w-4/5 md:w-3/5 xl:w-2/5 mb-4">
        {[
          'SEC Filings',
          'Patents',
          'Clinical Trials',
          'Government Contracts',
          'Lobbying Data',
          'Congressional Trades',
        ].map((dataset) => (
          <a
            key={dataset}
            href="https://docs.sov.ai/get-started/tutorials"
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 py-1 bg-gray-800 text-gray-300 text-xs md:text-sm rounded-full cursor-pointer
                      border border-transparent hover:border-blue-500 hover:bg-gray-700 
                      transition-all duration-300"
          >
            {dataset}
          </a>


        ))}
      </div>

      
      {/* Chart / Graphic */}
      <div className="flex flex-row justify-center items-center gap-2 w-11/12 md:w-8/12 max-w-[1200px] my-4">
        <img
          src="https://cdn.dorik.com/60fa5a0723068000111d7544/60fa5bbd23068000111d754a/images/c12-(2)_v1t1iwq7.svg"
          alt="chart"
          className="w-full h-full"
        />
      </div>
    </div>
  );
};
