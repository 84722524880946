import { Reversed, Done } from 'assets/images';
import { Button } from 'app/components/Button';

const CodeBlock = () => {
  return (
    <div className="bg-[#1a1b26] rounded-lg p-6 font-mono text-lg relative overflow-hidden w-full md:w-[500px] lg:w-[600px] xl:w-[700px]">
      {/* Terminal dots */}
      <div className="flex gap-3 mb-6">
        <div className="w-4 h-4 rounded-full bg-[#ff5f56]"></div>
        <div className="w-4 h-4 rounded-full bg-[#ffbd2e]"></div>
        <div className="w-4 h-4 rounded-full bg-[#27c93f]"></div>
      </div>
      
      {/* Code content */}
      <div className="space-y-4">
        <div className="text-[#565869]"># Import the sovai library</div>
        <div>
          <span className="text-[#ff79c6]">import</span>{" "}
          <span className="text-[#bd93f9]">sovai</span>{" "}
          <span className="text-[#ff79c6]">as</span>{" "}
          <span className="text-[#bd93f9]">sov</span>
        </div>
        
        <div className="text-[#565869]"># Fetch data based on a query</div>
        <div>
          <span className="text-[#bd93f9]">sov</span>
          <span className="text-gray-300">.data(</span>
          <span className="text-[#50fa7b]">'query'</span>
          <span className="text-gray-300">)</span>
        </div>
        
        <div className="text-[#565869]"># Generate plots for data visualization</div>
        <div>
          <span className="text-[#bd93f9]">sov</span>
          <span className="text-gray-300">.plots(</span>
          <span className="text-[#50fa7b]">'query'</span>
          <span className="text-gray-300">)</span>
        </div>
        
        <div className="text-[#565869]"># Create reports based on a query</div>
        <div>
          <span className="text-[#bd93f9]">sov</span>
          <span className="text-gray-300">.reports(</span>
          <span className="text-[#50fa7b]">'query'</span>
          <span className="text-gray-300">)</span>
        </div>
        
        <div className="text-[#565869]"># Perform computation tasks</div>
        <div>
          <span className="text-[#bd93f9]">sov</span>
          <span className="text-gray-300">.compute(</span>
          <span className="text-[#50fa7b]">'query'</span>
          <span className="text-gray-300">)</span>
        </div>
      </div>
    </div>
  );
};

export const ReversedCard = () => {
  return (
    <div
      className="w-full h-full bg-cover flex flex-col md:flex-row items-center justify-center py-16 px-6 lg:py-24 lg:px-12"
      style={{
        backgroundImage: `url(${Reversed})`,
      }}
    >
      {/* Increased gap between columns */}
      <div className="flex flex-col md:flex-row w-4/5 items-center justify-center gap-x-10 lg:gap-x-20 xl:gap-x-32">
        {/* Left column with text */}
        <div className="flex flex-col justify-start items-start gap-y-6 md:gap-y-4 lg:gap-y-6 xl:gap-y-10 md:w-1/2">
          <h2 className="text-4xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-bold">
            How does it Work?
          </h2>
          <div className="flex flex-row items-center justify-start gap-x-4 text-gray-300 text-lg lg:text-xl xl:text-2xl">
            <img src={Done} alt="Completed Step 1" className="w-5 h-5" />
            <span><strong>Step 1:</strong> Choose a dataset you are interested in</span>
          </div>
          <div className="flex flex-row items-center justify-start gap-x-4 text-gray-300 text-lg lg:text-xl xl:text-2xl">
            <img src={Done} alt="Completed Step 2" className="w-5 h-5" />
            <span><strong>Step 2:</strong> Extract signals from the datasets</span>
          </div>
          <div className="flex flex-row items-center justify-start gap-x-4 text-gray-300 text-lg lg:text-xl xl:text-2xl">
            <img src={Done} alt="Completed Step 3" className="w-5 h-5" />
            <span><strong>Step 3:</strong> Improve with statistical software</span>
          </div>
          <div className="flex flex-row justify-center items-center mt-6">
            <Button
              className="
                px-5
                py-3
                rounded-full
                text-white
                bg-green-500
                hover:bg-green-600
                transition-all
                duration-300
                ease-in-out
                shadow-md
                hover:shadow-[0_0_20px_rgba(128,0,128,0.4)]
                border
                border-transparent
                hover:border-purple-500
                ml-6
              "
              onClick={() => {
                window.open('https://docs.sov.ai/get-started/tutorials', '__blank');
              }}
            >
              ✨ See Example Notebooks
            </Button>
          </div>


        </div>

        {/* Right column with code block */}
        <div className="flex flex-col justify-center items-center mt-10 md:mt-0 md:w-1/2">
          <CodeBlock />
        </div>
      </div>
    </div>
  );
};
